html,
body {
  height: 100%;
  width: 100%;
}
body {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  -webkit-tap-highlight-color: #222222;
}
hr {
  border-color: #78AE48;
  border-width: 4px;
  max-width: 60%;
  margin-left: 0;
  margin-top: 0;
}
hr .light {
  border-color: white;
}
a {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
  color: #78AE48;
}
a:hover,
a:focus {
  color: #5f8a39;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans Condensed', 'Helvetica Neue', Arial, sans-serif;
}
p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}
.bg-dark {
  background-color: #525B71;
  color: white;
}
.text-faded {
  color: rgba(255, 255, 255, 0.7);
}
.text-primary-faded {
  color: rgba(85, 85, 85, 0.7);
}
section {
  padding-bottom: 50px;
}
section .colored-border div {
  height: 5px;
}
section .colored-border div:nth-child(odd) {
  background-color: #78AE48;
}
section .colored-border div:nth-child(even) {
  background-color: #ed8b16;
}
section .cookie {
  margin-top: 55px;
}
aside {
  padding: 50px 0;
}
.no-padding {
  padding: 0;
}
.navbar-default {
  background-color: #aaaaaa;
  border-color: #eeeeee;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
}
.navbar-default .navbar-header .navbar-brand {
  padding: 0 5px;
  color: rgba(85, 85, 85, 0.7);
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
.navbar-default .navbar-header .navbar-brand:hover,
.navbar-default .navbar-header .navbar-brand:focus {
  color: #5f8a39;
}
.navbar-default .navbar-header .navbar-brand > img.logo {
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-height: 50px;
  padding: 7px;
}
.navbar-default .navbar-header .navbar-toggle {
  font-weight: 700;
  font-size: 12px;
  color: #5f8a39;
  text-transform: uppercase;
}
.navbar-default .nav > li > a {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  color: white;
}
.navbar-default .nav > li > a:focus,
.navbar-default .nav > li > a:hover {
  color: #d6d6d5;
}
.navbar-default .nav > li.active > a,
.navbar-default .nav > li.active > a:focus {
  color: #d6d6d5 !important;
  background-color: transparent;
}
.navbar-default .nav > li.active > a:hover,
.navbar-default .nav > li.active > a:focus:hover {
  background-color: transparent;
}
@media (min-width: 768px) {
  .navbar-default {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.3);
  }
  .navbar-default .navbar-header .navbar-brand {
    color: rgba(85, 85, 85, 0.7);
  }
  .navbar-default .navbar-header .navbar-brand:hover,
  .navbar-default .navbar-header .navbar-brand:focus {
    color: #5f8a39;
  }
  .navbar-default .nav > li > a,
  .navbar-default .nav > li > a:focus {
    color: white;
  }
  .navbar-default:hover {
    color: #d6d6d5;
  }
  .navbar-default.affix {
    background-color: #78AE48;
  }
  .navbar-default.affix .navbar-header .navbar-brand {
    font-size: 14px;
  }
  .navbar-default.affix .navbar-header .navbar-brand:hover,
  .navbar-default.affix .navbar-header .navbar-brand:focus {
    color: #d6d6d5;
  }
  .navbar-default.affix .nav > li > a {
    color: white;
  }
  .navbar-default.affix .nav > li > a:focus,
  .navbar-default.affix .nav > li > a.active,
  .navbar-default.affix .nav > li > a:hover {
    color: #d6d6d5;
  }
}
header {
  position: relative;
  width: 100%;
  min-height: 100%;
  background-image: url('../img/header.jpg');
  background-position: center top;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  text-align: center;
  color: white;
}
header .header-content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
  padding: 0 50px;
}
header .header-content .header-content-inner {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
header .header-content .header-content-inner img.logo {
  width: 100%;
}
@media (min-width: 768px) {
  header .header-content .header-content-inner img.logo {
    width: 80%;
  }
}
.section-heading {
  margin-top: 0;
  font-family: 'Open Sans Condensed', 'Helvetica Neue', Arial, sans-serif;
  font-size: 28px;
}
.service-box {
  color: white;
  padding: 20px 30px;
  min-height: 120px;
  text-align: center;
}
.service-box .sr-icons {
  height: 36px;
}
@media (min-width: 768px) {
  .service-box {
    min-height: 130px;
  }
}
@media (min-width: 992px) {
  .service-box {
    text-align: left;
    /* min-height: 185px; */
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .service-box {
    min-height: 155px;
  }
}
.service-box p {
  margin-bottom: 0;
}
.service-box.box-dark {
  background-color: #525B71;
}
.service-box.box-light {
  background-color: #777D8D;
}
.service-box .service-box-caption,
.service-box .service-box-description {
  opacity: 0.8;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
}
.service-box:hover .service-box-caption,
.service-box:hover .service-box-description {
  opacity: 1;
}
.service-box:focus {
  outline: none;
}
.portfolio-box {
  position: relative;
  display: block;
  max-width: 650px;
  margin: 0 auto;
}
.portfolio-box .portfolio-box-caption {
  color: white;
  opacity: 0;
  display: block;
  background: rgba(119, 119, 119, 0.9);
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
}
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
}
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category,
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  padding: 0 15px;
}
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
}
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
  font-size: 18px;
}
.portfolio-box:hover .portfolio-box-caption {
  opacity: 1;
}
.portfolio-box:focus {
  outline: none;
}
@media (min-width: 768px) {
  .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category {
    font-size: 16px;
  }
  .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
    font-size: 22px;
  }
}
.call-to-action {
  padding: 15px;
  min-height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.call-to-action .content {
  text-align: center;
}
.call-to-action h2 {
  margin: 10px 0;
  color: white;
  display: inline-block;
}
.call-to-action .btn {
  margin: 0 20px;
  color: rgba(102, 102, 102, 0.7);
}
.call-to-action.bg-orange {
  background-color: #ed8b16;
}
.call-to-action.bg-green {
  background-color: #78AE48;
}
.text-primary {
  color: #78AE48;
}
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}
.btn-default {
  color: #222222;
  background-color: white;
  border-color: white;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
}
.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  color: #222222;
  background-color: #f2f2f2;
  border-color: #ededed;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: white;
  border-color: white;
}
.btn-default .badge {
  color: white;
  background-color: #222222;
}
.btn-primary {
  color: white;
  background-color: #78AE48;
  border-color: #78AE48;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: white;
  background-color: #6c9c41;
  border-color: #67953e;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #78AE48;
  border-color: #78AE48;
}
.btn-primary .badge {
  color: #78AE48;
  background-color: white;
}
.btn {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  border-radius: 10px;
  text-transform: uppercase;
  color: rgba(102, 102, 102, 0.3);
  border: none;
  padding: 8px 12px;
  margin: 0 30px;
}
.btn-xl {
  padding: 15px 30px;
}
::-moz-selection {
  color: white;
  text-shadow: none;
  background: #222222;
}
::selection {
  color: white;
  text-shadow: none;
  background: #222222;
}
img::selection {
  color: white;
  background: transparent;
}
img::-moz-selection {
  color: white;
  background: transparent;
}
img.width-100 {
  max-width: 100%;
  margin-bottom: 15px;
}
#banner1 {
  min-width: 100%;
  min-height: 400px;
  /* background: #fff url('@{img}/esterno.jpg') center/cover no-repeat; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-image: url('../img/esterno.jpg');
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  #banner1 {
    height: 600px;
  }
}
.text-green {
  color: #78AE48;
}
footer {
  background-color: #78AE48;
  min-height: 40px;
}
footer .footer-inner {
  padding: 10px 0;
  color: white;
  text-align: center;
  height: 100%;
}
@media (max-width: 340px) {
  footer .footer-inner .block-on-xxs {
    display: block;
  }
}
